import {
  ComponentRef,
  EditorReadyFn,
  FlowEditorSDK,
  OnEventFn,
  GetAppManifestFn,
  EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { WidgetPluginInterfaces } from '@wix/widget-plugins-interfaces';

import {
  RATING_WIDGET_ID,
  REVIEWS_STANDALONE_WIDGET_ID,
  REVIEWS_PLUGGABLE_WIDGET_ID,
  RATING_LIST_WIDGET_ID,
  REVIEWS_APP_ID,
  ECOM_APP_DEF_ID,
  BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
} from './app-ids';
import { SlotContext } from './types/common-types';
import { BM } from './editor/constants/routes';
import { MAIN_ARTICLE, RATING_SUMMARY_MAIN_ARTICLE } from '~settings-commons/constants/article-ids';

const OPEN_BM_ACTION = 'openBMAction';
const SLOTS_WIDGET_IDS = [REVIEWS_PLUGGABLE_WIDGET_ID, RATING_WIDGET_ID, RATING_LIST_WIDGET_ID];
const ALL_WIDGET_IDS = [...SLOTS_WIDGET_IDS, REVIEWS_STANDALONE_WIDGET_ID];
let _flowAPI: EditorScriptFlowAPI | undefined;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  { initialAppData: { appDefinitionId } },
  flowAPI,
) => {
  const { translations } = flowAPI;
  const t = translations.t.bind(translations);
  _flowAPI = flowAPI;
  return appManifestBuilder
    .configureWidget(REVIEWS_STANDALONE_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'reviews' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: MAIN_ARTICLE,
        });
    })
    .configureWidget(REVIEWS_PLUGGABLE_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'reviews' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: MAIN_ARTICLE,
        });
    })
    .configureWidget(RATING_LIST_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'ratingList' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: RATING_SUMMARY_MAIN_ARTICLE,
        });
    })
    .configureWidget(RATING_WIDGET_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'rating' });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-reviews'),
          actionId: OPEN_BM_ACTION,
        })
        .set('mainAction2', {
          behavior: 'DEFAULT1',
        })
        .set('help', {
          id: RATING_SUMMARY_MAIN_ARTICLE,
        });
    })
    .build();
};

export const onEvent: OnEventFn = async (event, editorSDK) => {
  if (event.eventType === 'widgetAdded' && ALL_WIDGET_IDS.includes(event.eventPayload?.widgetId)) {
    const { slotContext } = await setupSlotContext(
      event.eventPayload?.componentRef,
      event.eventPayload?.widgetId,
      editorSDK,
    );

    _flowAPI?.bi?.report({
      evid: 8071,
      src: 69,
      endpoint: 'livesite-reviews',
      params: {
        app_id: (slotContext as any).appDefinitionId,
        plugin_id: event.eventPayload?.widgetId,
        site_builder_type: _flowAPI?.environment.isEditorX
          ? 'editor_x'
          : _flowAPI?.environment.isClassicEditor
          ? 'editor'
          : _flowAPI?.environment.isADI
          ? 'adi'
          : '',
      },
    });

    if (event.eventPayload.widgetId === REVIEWS_PLUGGABLE_WIDGET_ID) {
      await editorSDK.editor.openSettingsPanel('', {
        componentRef: event.eventPayload.componentRef,
      });
    }
    const isBlockPPInstalled = await isBlocksProductPageInstalled(editorSDK);
    if (
      _flowAPI?.experiments.enabled('specs.reviews.controlledInstallFlowIntoStores') &&
      !isBlockPPInstalled
    ) {
      controllWidgetInstallationIntoStores(event, slotContext, editorSDK);
    }
  }

  if (event.eventPayload?.id === OPEN_BM_ACTION) {
    editorSDK.editor.openDashboardPanel('token', { url: BM, closeOtherPanels: true });
  }
};

const controllWidgetInstallationIntoStores = async (
  event: { eventPayload?: { widgetId: string } },
  slotContext: any,
  editorSDK: FlowEditorSDK,
) => {
  // The following logic controlls reviews installation into stores.
  // (user triggers install) RATING_LIST_WIDGET_ID -> REVIEWS_PLUGGABLE_WIDGET_ID -> RATING_WIDGET_ID
  // (user triggers install) REVIEWS_PLUGGABLE_WIDGET_ID -> RATING_WIDGET_ID
  // (user triggers install) RATING_WIDGET_ID -> REVIEWS_PLUGGABLE_WIDGET_ID

  const installedWidgets = await geInstalledtReviewsWidgets(ECOM_APP_DEF_ID, editorSDK);
  if (slotContext.appDefinitionId === ECOM_APP_DEF_ID && event.eventPayload?.widgetId) {
    const storesApi = await getStoresApi(editorSDK);
    if (
      event.eventPayload.widgetId === RATING_LIST_WIDGET_ID &&
      !installedWidgets.includes(REVIEWS_PLUGGABLE_WIDGET_ID)
    ) {
      const t = _flowAPI?.translations.t.bind(_flowAPI?.translations);
      await editorSDK.editor.openProgressBar('', {
        title: t?.('installation-loader.title') || '',
        totalSteps: 2,
        currentStep: 0,
        stepTitle: t?.('installation-loader.progress-0') || '',
      });
      await storesApi.installReviewsPlugin({
        targetSlot: 'product-page-bottom-slot',
        widgetPluginPointer: {
          appDefinitionId: REVIEWS_APP_ID,
          widgetId: REVIEWS_PLUGGABLE_WIDGET_ID,
        },
      });
      await editorSDK.editor.updateProgressBar('', {
        currentStep: 1,
        stepTitle: t?.('installation-loader.progress-1') || '',
      });
      await editorSDK.editor.closeProgressBar('', {});
    } else if (
      event.eventPayload.widgetId === RATING_WIDGET_ID &&
      !installedWidgets.includes(REVIEWS_PLUGGABLE_WIDGET_ID)
    ) {
      await storesApi.installReviewsPlugin({
        targetSlot: 'product-page-bottom-slot',
        widgetPluginPointer: {
          appDefinitionId: REVIEWS_APP_ID,
          widgetId: REVIEWS_PLUGGABLE_WIDGET_ID,
        },
      });
    } else if (
      event.eventPayload.widgetId === REVIEWS_PLUGGABLE_WIDGET_ID &&
      !installedWidgets.includes(RATING_WIDGET_ID)
    ) {
      await storesApi.installReviewsPlugin({
        targetSlot: 'product-page-details-slot-1',
        widgetPluginPointer: { appDefinitionId: REVIEWS_APP_ID, widgetId: RATING_WIDGET_ID },
      });
    }
  }
};

interface IInstallReviewsPluginProps {
  targetSlot: 'product-page-bottom-slot' | 'product-page-details-slot-1';
  widgetPluginPointer: { appDefinitionId: string; widgetId: string };
}

const getStoresApi = async (
  editorSDK: any,
): Promise<{ installReviewsPlugin: (args: IInstallReviewsPluginProps) => Promise<any> }> => {
  const storesPublicApi = await editorSDK.application.getPublicAPI('', {
    appDefinitionId: ECOM_APP_DEF_ID,
  });

  return storesPublicApi;
};

async function geInstalledtReviewsWidgets(hostAppDefinitionId: string, editorSDK: FlowEditorSDK) {
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', REVIEWS_APP_ID);
  const appComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    '',
    appData.applicationId,
  );
  return appComponents
    .filter((comp) => {
      try {
        const content = JSON.parse((comp as any).tpaData.content);
        return (
          content.slotContext.appDefinitionId === hostAppDefinitionId &&
          content.slotContext.type === 'slot'
        );
      } catch (error) {
        return false;
      }
    })
    .map((comp) => comp.widgetId);
}

const isBlocksProductPageInstalled = (editorSDK: FlowEditorSDK): Promise<boolean> => {
  return editorSDK.application.isApplicationInstalled('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
};

async function setupSlotContext(
  componentRef: ComponentRef,
  widgetId: string,
  editorSDK: FlowEditorSDK,
) {
  const ancestorRefs = await editorSDK.components.getAncestors('', {
    componentRef,
  });
  const ancestorsData: any[] = await Promise.all(
    ancestorRefs.map(async (ref) => {
      return editorSDK.components.data.get('', { componentRef: ref });
    }),
  );

  if (SLOTS_WIDGET_IDS.includes(widgetId)) {
    const parentAppDefId: string | undefined = ancestorsData.find(
      (d) => !!d?.appDefinitionId,
    )?.appDefinitionId;
    return setSlotContext(
      { type: 'slot', appDefinitionId: parentAppDefId, interfaces: getInterface(widgetId) },
      editorSDK,
      componentRef,
    );
  } else {
    return setSlotContext({ type: 'not-slot' }, editorSDK, componentRef);
  }
}

const getInterface = (widgetId: string) => {
  switch (widgetId) {
    case REVIEWS_PLUGGABLE_WIDGET_ID:
      return [WidgetPluginInterfaces.REVIEWS];
    case RATING_WIDGET_ID:
      return [WidgetPluginInterfaces.RATINGS_SUMMARY];
    case RATING_LIST_WIDGET_ID:
      return [WidgetPluginInterfaces.RATINGS_SUMMARY_OOI_LIST];
    default:
      throw Error(`WidgetId ${widgetId} does not implement any slot interface!`);
  }
};

const setSlotContext = (
  context: SlotContext,
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  return editorSDK.document.tpa.data.set('', {
    compRef: componentRef,
    key: 'slotContext',
    value: context,
    scope: 'COMPONENT',
  });
};

export const exports = {};
